(function($) {
    // Smooth Scroll To Anchor
    $(document).on('click', 'a[href*="#"]', function (event) {
        event.preventDefault()
        var target = $(this).attr('href')

        if ($(target).length) {
            $('html, body').animate({
                scrollTop: $(target).offset().top - $('.site-header').outerHeight()
            }, 700, 'linear', function(){
                $('html, body').scrollTop($(target).offset().top - $('.site-header').outerHeight());
            });  
        }
    });
})( jQuery );